import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  ServerError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

export const parseError = (e: any) => {
  return {
    message:
      e?.networkError?.result?.errors?.[0]?.extensions ||
      e?.graphQLErrors?.[0]?.message,
    code: e?.graphQLErrors?.[0]?.code as undefined | number | null,
  };
};

type CreateClientOptions = { onErrorReceived(v: any): void };

const errorCodes = ({ onErrorReceived }: CreateClientOptions) =>
  onError((error) => {
    const { networkError } = error;
    const { code } = parseError(error);
    onErrorReceived(Math.random())
    if (networkError) { 
      const status = (networkError as ServerError).statusCode;
      if (
        networkError.toString() === "TypeError: Failed to fetch" ||
        [500, 504, 503, 502].includes(status) ||
        [500, 504, 503, 502].includes(code!)
      ) {
        onErrorReceived(500);
      }
    }
  });

const createClient = ({ onErrorReceived }: CreateClientOptions) => {
  const link = from([
    errorCodes({ onErrorReceived }),
    createHttpLink({
      uri: "https://gql.personalbrandmodel.com/",
    }),
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};

export default createClient;
