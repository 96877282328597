import {ROUTES} from "App";
import {useAppContext} from "AppContext";
import Menu from "assets/icons/menu.svg";
import classNames from "classnames/bind";
import Button from "components/Button";
import React, {Fragment, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import styles from "./index.module.scss";
import {URL_WORKBOOK_UA} from "../../constants/constants";
import {goWorkbook} from "../../utils/goWorkbook";
import {LanguageEnum} from "../../api/generated";

const cx = classNames.bind(styles);

export const SidebarControl = () => {
  const {toggleSidebar} = useAppContext();

  return (
    <button onClick={toggleSidebar} className={cx("BtnSidebarControl")}>
      <img src={Menu} alt=""/>
    </button>
  );
};

const Sidebar = () => {
  const [t, {language}] = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const {toggleSidebar, showSidebar} = useAppContext();
  const goToTest = () => history.push(ROUTES.test);
  useEffect(() => {
    document.body.style.overflow = showSidebar ? "hidden" : "";
    if (!showSidebar) contentRef.current?.scrollTo?.(0, 0);
  }, [showSidebar]);

  return (
    <Fragment>
      <aside ref={contentRef} className={cx("Component", showSidebar && "open")}>
        <div className={cx("Content")}>
          <div className={cx("Row")}>
            <div className={cx("RowContent", "Logo")}>
              <img
                onClick={() => {
                  history.push(ROUTES.home);
                  toggleSidebar();
                }}
                src={require("assets/logo.svg")}
                alt="Fly_academy"
              />
            </div>
            <div className={cx("RowContent", "BtnCloseWrapper")}>
              <button onClick={toggleSidebar} className={cx("BtnClose")}>
                <img src={require("assets/icons/close.svg")} alt="Close"/>
              </button>
            </div>
          </div>
          {/*<NavLink*/}
          {/*  label={t("sidebar.nav.reviews")}*/}
          {/*  target="participants"*/}
          {/*  onClick={toggleSidebar}*/}
          {/*/>*/}
          <NavLink
            label={t("sidebar.nav.about")}
            target="about-test"
            onClick={toggleSidebar}
          />
          <NavLink
            label={t("Your workbook")}
            target="about-test"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              goWorkbook(language as LanguageEnum)
            }}
          />
          <NavLink
            label={t("sidebar.nav.models")}
            target="model"
            onClick={toggleSidebar}
          />
          {/*<NavLink*/}
          {/*  label={t("sidebar.nav.benefits")}*/}
          {/*  target="benefits"*/}
          {/*  className={cx("BeforeBtnStartRow")}*/}
          {/*  onClick={(e: any) => {*/}
          {/*    e.preventDefault()*/}
          {/*    e.stopPropagation()*/}
          {/*    window.open('/promo', '_blank')*/}
          {/*  }}*/}
          {/*/>*/}

          <div className={cx("Row")}>
            <div className={cx("RowContent", "BtnStartWrapper")}>
              <Button
                onClick={() => {
                  toggleSidebar();
                  goToTest();
                }}
                className={cx("BtnStart")}
              >
                {t("sidebar.startTest")}
              </Button>
            </div>
          </div>

          <div className={cx("Row", "footer")}>
            <div className={cx("RowContent")}>
              <Link
                onClick={toggleSidebar}
                className={cx("NavLink")}
                to={ROUTES.blogs}
              >
                {t("sidebar.nav.blog")}
              </Link>
            </div>
            <div className={cx("RowContent")}>
              <a
                className={cx("SocialLink")}
                href="https://www.instagram.com/flyacademy_/"
                target="_blank"
              >
                <img
                  src={require("assets/icons/instagram.svg")}
                  alt="Instagram"
                />
              </a>
            </div>
            <div className={cx("RowContent")}>
              <a
                className={cx("SocialLink")}
                href="https://facebook.com/personalbrandmodel/"
                target="_blank"
              >
                <img
                  src={require("assets/icons/facebook.svg")}
                  alt="Facebook"
                />
              </a>
            </div>
          </div>
        </div>
      </aside>
    </Fragment>
  );
};

const NavLink = (props: {
  label: string;
  target: string;
  onClick(a: any): void | Function;
  className?: string;
}) => {
  return (
    <div className={cx("Row", props.className)}>
      <div className={cx("RowContent", "RowLink")}>
        <HashLink
          smooth
          className={cx("NavLink")}
          onClick={props.onClick}
          scroll={el => el.scrollIntoView({behavior: 'instant', block: 'start'} as any)}
          to={`/#${props.target}`}
        >
          {props.label}
        </HashLink>
      </div>
    </div>
  );
};

export default Sidebar;
