import React, { Fragment, useState } from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { SidebarControl } from "components/Sidebar";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Paginate from "react-paginate";
import { ReactComponent as IconArrowLeft } from "assets/icons/arrow_sm-left.svg";
import { ReactComponent as IconArrowRight } from "assets/icons/arrow_sm-right.svg";
import { RouteComponentProps } from "react-router-dom";
import { ROUTES } from "App";
import AdsBlock from "./AdsBlock";
import { usePostsQuery } from "api/generated";

const cx = classNames.bind(styles);

const PageBlogList = ({ history }: RouteComponentProps) => {
  const [t, { language: lang }] = useTranslation();
  const [page, setPage] = useState(1);
  const postsQuery = usePostsQuery({
    variables: {
      page,
      lang,
    },
  });

  let posts = postsQuery.data?.posts?.posts ?? [];
  const { postsPerPage, postsTotal } = postsQuery.data?.posts?.pageInfo ?? {};
  const totalPages = Math.ceil((postsTotal ?? 0) / (postsPerPage ?? 0));
  const hasMaxPosts = posts.length === postsPerPage;
  const goToTest = () => history.push(ROUTES.test);

  const renderPost = (post: typeof posts[number], index: number) => {
    const insertAds = hasMaxPosts
      ? index === 2
      : posts.length > 4
      ? index === 3
      : index === posts.length - 1;

    if (!post) return null;

    return (
      <Fragment key={post.id}>
        {insertAds && index !== 0 && <AdsBlock goToTest={goToTest} />}
        <div className={cx("Post")}>
          <div className={cx("PostImage")}>
            <img src={post.photo ?? ""} alt="" />
          </div>
          <div className={cx("PostDetails")}>
            <h3>{post.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: post.shortly ?? "" }} />
            <Button onClick={() => history.push(ROUTES.blog(post.slug ?? ""))}>
              {t("pageBlogList.card.read")}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div className={cx("Component")}>
      <header className={cx("Header", "container")}>
        <SidebarControl />
      </header>
      <div className="container">
        <h1 className={cx("Title")}>{t("pageBlogList.title")}</h1>

        {postsQuery.loading ? (
          <div className={cx("Loader")}>
            <img src="/assets/loader.gif" alt="" />
          </div>
        ) : (
          <Fragment>
            <div className={cx("List", !hasMaxPosts && "short")}>
              {posts.map(renderPost)}
            </div>

            {totalPages > 1 && (
              <Paginate
                pageCount={totalPages}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                forcePage={page - 1}
                onPageChange={({ selected }) => setPage(selected + 1)}
                containerClassName={cx("Paginate")}
                activeClassName={cx("active")}
                breakClassName={cx("PaginatePage", "PaginatePageDots")}
                pageClassName={cx("PaginatePage")}
                previousClassName={cx("PaginatePage", "PaginatePagePrevious")}
                nextClassName={cx("PaginatePage", "PaginatePageNext")}
                previousLabel={<IconArrowLeft />}
                nextLabel={<IconArrowRight />}
              />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default PageBlogList;
