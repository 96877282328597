import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import {useTranslation} from "react-i18next";
import Button from "components/Button";

const cx = classNames.bind(styles);

const AdsBlock = ({
                    className,
                    goToTest,
                  }: {
  className?: string;
  goToTest(): void;
}) => {
  const [t] = useTranslation();

  return (
    <div className={cx("AdsBlock", className)}>
      <div className={cx("AdsBlockContent")}>
        <div className={cx("AdsBlockDetails")}>
          <h3 dangerouslySetInnerHTML={{__html: t("pageBlogList.ads.title")}}/>
          <p>{t("pageBlogList.ads.text")}</p>
          <Button className={cx('btn-blog')} onClick={goToTest}>{t("pageBlogList.ads.btn")}</Button>
        </div>

        <img
          className={cx("AdsBlockImg")}
          src={require("assets/images/ads.png")}
          alt=""
        />
      </div>
    </div>
  );
};

export default AdsBlock;
