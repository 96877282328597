export const FACEBOOK_CLIENT_KEY = window.location.origin === "http://localhost:3000"
  ? "1331019677257673" // my facebook id for check on the host: localhost:3000
  : "968095210341613"

export const GOOGLE_CLIENT_ID = "206183431149-uosrmlmnrshlb5s9g3fmal4de5uua11i.apps.googleusercontent.com"

export const INSTAGRAM_APP_ID = '656997281665724'
export const URL_WORKBOOK_UA = 'https://www.yakaboo.ua/ua/kniga-brendu-osobistosti-interaktivna-metodichka.html'
export const URL_WORKBOOK_EN = 'https://www.yakaboo.ua/ua/the-personal-brand-manual.html?gad_source=1&gclid=Cj0KCQjwq86wBhDiARIsAJhuphnTclmUpxiwwwxSMflsqdwhbVPL-yimOoIJXGXu2yaX81cg_Mtq5hUaAhobEALw_wcB'

export enum VIDEO_COLORS {
  green = "#c0e30e",
  pink = "#fc8ab8",
  violet = "#bb86ff"
}

export const PRICE = {
  old: '19.99',
  new: '0',
  currency: '$'
}

