import {LanguageEnum} from "../api/generated";
import {URL_WORKBOOK_EN, URL_WORKBOOK_UA} from "../constants/constants";

export const goWorkbook = (lng: LanguageEnum) => {
  let url = ''
  if (lng.toLowerCase() !== LanguageEnum.En.toLowerCase()) {
    url = URL_WORKBOOK_UA
  } else {
    url = URL_WORKBOOK_EN
  }
  window.open(url, '_blank')
}