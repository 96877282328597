import React, {useState} from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export type ButtonProps = {
  /**
   * @default true
   * draw arrow as pseudo class
   */
  withArrow?: boolean;
  loading?: boolean;
  arrowDirection?: 'bottom' | 'right' | 'top'
  transparent?: boolean
}
const Button = ({
                  className,
                  withArrow = true,
                  loading,
                  disabled,
                  transparent = false,
                  arrowDirection,
                  ...props
                }: JSX.IntrinsicElements["button"] & ButtonProps) => {
    const [active, setActive] = useState<boolean>(false)
    return (
      <button
        onTouchStart={() => {
          !active && setActive(true)
        }}
        onTouchEnd={() => {
          active && setActive(false)
        }}
        onTouchMove={() => {
          active && setActive(false)
        }}

        disabled={disabled || loading}
        className={cx(
          "Component",
          withArrow && "arrow",
          loading && "loading",
          transparent && 'transparent',
          active && 'ComponentActive',
          arrowDirection,
          className,
        )}

        {...props}
      >
        {
          transparent
            ? <span>{props.children}</span>
            : props.children
        }
      </button>
    );
  }
;

export default Button;
