import React, {PureComponent, useState} from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Button from "components/Button";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {ROUTES} from "App";
import {AppContext, useAppContext} from "AppContext";

const cx = classNames.bind(styles);

export type Props = {
  status?: 404 | 500;
  description?: string,
  isTechnicalWork?: boolean
};

export class ErrorBoundary extends PureComponent {
  context!: React.ContextType<typeof AppContext>;
  contextType = AppContext!;

  componentDidCatch() {
    this.context.changeErrorCode(500);
  }

  render() {
    const errorCode = this.context.errorCode;
    if (errorCode) {
      return <PageError status={errorCode}  {...this.props}/>;
    }
    return this.props.children;
  }
}

const PageError = ({status = 404, ...props}: Props) => {
  const [t] = useTranslation();
  const {changeErrorCode} = useAppContext()
  const history = useHistory();
  const [isDev, setIsDev] = useState(false)

  const onClickHomeBtn = () => {
    history?.push(ROUTES.home);
    changeErrorCode(null)
  };

  const description = `pageError.${status}`
  return (
    <main className={`${cx("Component")} ${props.isTechnicalWork && cx('ComponentTechnical')}`}>
      <div>
        <h1 className={cx("Status")}>{status}</h1>
        <p className={cx("Description")}>{t(props.description || description)}</p>
        <Button onClick={onClickHomeBtn}>{t("pageError.homeBtn")}</Button>
      </div>
      {props.isTechnicalWork && <div className={cx("dev")}>
        {!isDev && <span onClick={() => setIsDev(true)}> Разработчикам </span>}
        {isDev && <input type="text" onChange={({target: {value}}) => {
          if (value.length === 3 && +value === 111) {
            localStorage.setItem("testing", "true")
            window.location.reload()
          }
        }}/>}
      </div>}
    </main>
  );
};

export default PageError;
