import React, {useState} from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import {Trans, useTranslation} from "react-i18next";
import Button from "components/Button";
import {Link} from "react-router-dom";
import {ROUTES} from "App";

const cx = classNames.bind(styles);

const KEY = "cookiesAgreement";

const CookiesAgreement = () => {
  const [t] = useTranslation();
  const [hide, setHide] = useState(!!localStorage.getItem(KEY));

  const onAgree = () => {
    setHide(true);
    localStorage.setItem(KEY, "agree");
  };

  return (
    <div className={cx("Component", hide && "hide")}>
      <p>
        <Trans i18nKey="cookies.text">
          <Link to={ROUTES.policy}>.</Link>
        </Trans>
      </p>
      <Button className={cx("Btn")} withArrow={false} onClick={onAgree}>
        {t("cookies.btn")}
      </Button>
    </div>
  );
};

export default CookiesAgreement;
