import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import ru from "./ru";
import uk from "./uk";
import en from "./en";

const resources = {
  en: {
    translation: en
  },
  ru: {
    translation: ru,
  },
  uk: {
    translation: uk,
  },

};

export type Languages = keyof typeof resources;
export const LANGUAGES = Object.keys(resources) as Languages[];

const DEFAULT_LNG: Languages = "en";

const KEY = "lng";
const getStoredLng = () => window.localStorage.getItem(KEY) as Languages | null;
export const setStoredLng = (lng: Languages = DEFAULT_LNG) => {
  window.localStorage.setItem(KEY, lng);
  return lng;
};

const getBrowserLng = () => {
  const {userLanguage, language} = navigator as any;
  const lng = language || userLanguage;
  return lng.slice(0, 2).toLowerCase() as string;
};

const getDefaultLng = () => {
  let lng = (getStoredLng() || DEFAULT_LNG || getBrowserLng()) as Languages;
  if (!LANGUAGES.includes(lng)) lng = setStoredLng();
  return lng;
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getDefaultLng(),
    fallbackLng: DEFAULT_LNG,

    interpolation: {
      escapeValue: false,
    },
  });
