import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "App";
const cx = classNames.bind(styles);

const Footer = () => {
  const [t] = useTranslation();

  const onClickToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className={cx("Component")} id={'footer'}>
      <div className={cx("Container", "container")}>
        <div className={cx("Content")}>
          <div className={cx("Company")}>
            <a
              href="https://flyacademy.co"
              target="_blank"
              className={cx("Logo")}
            >
              <img src={require("assets/logo.svg")} alt="" />
            </a>
            <p className={cx("Copyright")}>© Fly_Academy 2022</p>
          </div>
          <div className={cx("SocialLinks")}>
            <div>
              <a
                href="https://www.instagram.com/flyacademy_/"
                target="_blank"
              >
                <img src={require("assets/icons/instagram.svg")} />
              </a>
            </div>
            <div>
              <a
                href="https://facebook.com/personalbrandmodel/"
                target="_blank"
              >
                <img src={require("assets/icons/facebook.svg")} />
              </a>
            </div>
            <div className={cx("BtnTopBlock")}>
              <button onClick={onClickToTop} className={cx("BtnTop")}>
                <img src={require("assets/icons/arrow_sm-top.svg")} alt="" />
              </button>
            </div>
          </div>
          <div className={cx("Nav")}>
            <div>
              <Link to={ROUTES.policy}>{t("pageHome.footer.policy")}</Link>
            </div>
            <div>
              <a href="mailto:hello@personalbrandmodel.com">
                {t("pageHome.footer.help")}
              </a>
            </div>
            <div>
              <img className={cx('PaymentsImg')} src={require("assets/images/fondy_logo_cards.svg")} alt="" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
