import React, { useContext, useState, createContext } from "react";
import { Props as PageErrorProps } from "pages/Error";

type AppContextType = {
  errorCode: PageErrorProps["status"] | null;
  changeErrorCode: (v: PageErrorProps["status"] | null) => void;

  showSidebar: boolean;
  toggleSidebar: () => void;
};
export const AppContext = createContext({} as AppContextType);
export const useAppContext = () => useContext(AppContext);
export const AppProvider: React.FC<{}> = ({ children }) => {
  const [errorCode, changeErrorCode] = useState<AppContextType["errorCode"]>(
    null
  );

  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => setShowSidebar(!showSidebar);

  return (
    <AppContext.Provider
      value={{ errorCode, changeErrorCode, showSidebar, toggleSidebar }}
    >
      {children}
    </AppContext.Provider>
  );
};
