export default {
  ru: 'Ru',
  uk: 'Uk',
  en: 'En',
  'Your workbook': 'Your workbook',
  'More': 'More',
  moreInfo: 'More',
  orderAStrategy: 'Order a strategy',
  personalityBrandStrategy: `Personal brand strategy {{price}}`,
  whatReceive: 'what will you get?',
  simplePrice: 'Common price',
  strategyComplete: 'A ready-made strategy for building a personal brand, including a content guide.',
  yourself: {
    ticker: 'Express Yourself',
    MARKETER: {
      title: 'You know how to interact with people and which channels to use. Your efficiency and commitment to goals can only be admired. We have a few recommendations for you:\n',
      list: [
        'Organize everything around you to understand how much effort each task takes. In order to do this, you need to develop a precise project plan;\n',
        'Don’t lose yourself in others – have healthy boundaries and manage the amount of time necessary for communication; \n',
        'Be selective – all social connections are good but determine which ones are the most important, which meetings are more useful to you at this very moment, and which ones are okay to postpone;\n',
        'Readjust from focusing on yourself to focusing in your projects;\n',
        'You like competition, but your main competitor is yourself. Compare yourself to your yesterday’s version, not to other people;\n',
        'You need to put to test a theory before being sure about it. Try this out;\n',
        'You are a very independent and self-sufficient person. But at times support and help from others can be life-saving. Don’t be afraid to accept them. '
      ]
    },
    INSIDER: {
      title: 'You are active, consistent, you can  easily resolve complicated problems, and you have no equal in negotiations and private conversations. You are the best at word of mouth. Keep developing your skills:\n',
      list: [
        'Private conversations and communication in small groups are you superpower. Focus on this and try to negotiate this way;\n',
        'Dedicate a fixed amount of time daily to reflect and create new ideas. Do it regularly and write down everything that comes to mind;\n',
        'You have the ability to listen to others. It’s also important to go deep into yourself – notice processes, emotions, thoughts and feelings;\n',
        'Always prioritize in each particular situation, it will help to stay focused;',
        'Find a way to test your organization skills – hold an event, connect a group of people etc. \n'
      ]
    },
    EXPERT: {
      title: 'Personal growth is your priority. It’s high time you shared your knowledge, experience and perspective on life:',
      list: [
        'Balance out the time in your schedule that you spend alone and the time you spend with others;',
        'Spend enough time on planning and structuring your goals and tasks;',
        'Take a step back from work and try something new: be creative in new areas, don’t be afraid to make mistakes, and don’t give up on discovering the new. It’s important for you to get fulfilled in the areas where you aren’t yet an expert;\n',
        'Keep an eye on the amount of work and tasks – do not start new projects before finalizing the old ones;\n',
        'Take good care of yourself: manage the amount of duties and responsibility;',
        'Always remember about your input into work. When finalizing projects, don’t forget they were possible because of you and your skills in the first place. \n'
      ]
    },
    CREATOR: {
      title: 'Appeal, freedom and spontaneity characterize you best. What do you do next though to build a consistent and wholesome personality brand?\n',
      list: [
        'It’s time to think about your career. Which area would you like to grow in? What are your aspirations? Answer these questions and capture the goals;\n',
        'Pay attention to your pace of achieving these goals. Do not rush, it’s important to understand if there’s any progress towards the chosen direction;\n',
        'You are a creator, and creators like to be everywhere at all times. Try to open up your creativity and enthusiasm within a certain project or one workplace;\n',
        'Be careful with criticism: don’t forget about your self-efficiency and independence, appreciate yourself as a specialist;\n',
        'Professional burnout can be a relevant topic for you, so take a good care of yourself regularly;\n',
        'In spite of your grand plans, your life is not just about work. Various activities can keep you on your toes. Try out new things. '
      ]
    },
    A_LISTER: {
      title: 'It’s not new for you to be in the spotlight. Nevertheless, pay attention to a few things:',
      list: [
        'It feels like public speaking is made just for you; it’s a crucial step to develop your personal brand. Feel free to practice it more and grow in this area;',
        'Think about goals you are striving to reach, and link them with your values. Do they match?',
        'Widen your social circle – talk more with the people from your sphere;',
        'Sometimes you depend on other people’s opinions. Learn to appreciate yourself in spite of what society thinks of your actions;',
        'There’s a spark in you, but be careful not to burn out. Define an optimal number of activities per day. And remember about rest – it can be a part of your schedule, too. '
      ]
    }
  },
  persons_message: [
    'The thrill is in the first \n' +
    'for a week, requests have arrived for exactly what I want to develop in - visual support under the supervision of the LB \n' +
    'and business accounts. \n ' +
    'I had only to clearly formulate for myself what exactly I need. \n ' +
    'Thanks for all this. You 🔥🔥🔥 \n ',
    'Realized that I am acting now \n' +
    'not according to my model, and if I fix it💪, I will achieve the results I strive for! 🚀 \n',
    'Somewhere in the subconscious I guessed that I had the ability to create, but I did not believe it. Now I will try to somehow create something, since \n ' +
    'I am a Creator! 💫 \n' +
    '\n' +
    'The test itself is quite unusual, you answer similar questions in different ways, as if you are trying to mentally simulate a situation🙌 \n' +
    '\n',
    'Thanks for the interesting test! Direct questions all to the point, the pain of every digital employee) \n ',
    'My model is Insider. I\'ll make a \n ' +
    'buv trohi stupor і opposite 😅, \n' +
    'if she neglected a detailed strategy, then it was zerous \n' +
    'well, I can. Now get into your life! 🥰 \n ',
    'Дякую! Було дуже приємно 🖤🙈 бути серед тих, хто отримав такі знання. Я їх застосовую на собі і мій перший результат: просування на роботі.  '
  ],
  passTest: {
    free: 'Take a free personal model test now. It will take about 15 minutes.',
    plain: 'Do you already know your personal brand model? You have the opportunity to order a detailed strategy for building your personal brand.'
  },
  authorTitle: 'Authors of the personality model test:',
  author1: {
    name: 'Nataliya \nPirak',
    description: 'Founder of Fly_Academy marketing agency, for many\n' +
      'For years she has worked with large brands, including LVMH, JTI, Lactalis, Motorola, Nestle, Biersdorf, Goodyear, Loreal, Peugeot, Lifecell, Kimberly Clark and many others. Her portfolio includes collaborations with Jay-Z and David Lynch.'
  },
  author2: {
    name: 'Sofia \nLagutina',
    description: 'Psychologist, cognitive-behavioral therapist, schema-therapist,, researcher Emotional Mental Imagery Lab \n' +
      'Uppsala University, Sweden\n' +
      'Technical University of Munich.\n'
  },
  promo: {
    title: `Want more information to help you build a strong personal brand?`,
    descriptions: ['Detailed description of your personality model', 'Content recommendations', 'A diagram of the steps needed to work on your brand'],
    agree: 'Confirm',
    selectModel: 'Choose the model you want to get a detailed plan for',
    popupTitle: 'Attention!',
    popupDescription: 'More than 3 strategies were chosen. We understand that sometimes it is not easy to decide;) If you really need more, send a request to {{site}}',
    toPay: 'to pay ',
    understand: 'Understandable',
    btn: "Pay",

  },

  checkStatus: 'Checking the payment status. Wait please',
  error: {
    requiredField: "Please fill in this field",
    requiredField_checkbox: "Checkbox should be checked",
    globalError: "Checkbox should be checked",
    fillAtLeastOneField: "You must enter at least one social network",
  },

  sidebar: {
    nav: {
      reviews: "Reviews",
      about: "About the test",
      models: "Brand Models",
      benefits: "What do you get",
      blog: "Blog",
    },
    startTest: "Pass the test",
  },

  cookies: {
    text:
      "By continuing to visit our site, \nyou agree to the use of \n <0> cookies and the Privacy Policy </0>",
    btn: "Ok",
  },

  pageHome: {
    intro: {
      message:
        "You are a brand. \nFind out which methods of \nmanifestation are \nright for you",
      description: "Test to determine the model of a personal brand",
      btn: "More",
    },

    ticker: {
      create: "DO NOT copy, but create",
      brand: "your personal brand",
      target: "Who will benefit from the test",
    },

    about: {
      text: [
        "All people are unique in terms of personal characteristics. Everyone has their own approach to building a personal brand. There is no single solution." +
        "You can target people whose personal brand you like. You can analyze success, observe their actions. But as a result, there is a risk of going the wrong way, creating a fake image and losing yourself." +
        "It is important to stay true to yourself. We have created 5 personal brand models. Each model is based on the characteristics of the nervous system and the experience gained." +
        "Take the test, define your model and get a strategy for building a personal brand."],
      btn: "More",
    },

    participants: {
      title: "Who has already passed the test?",
    },

    chat: {
      title: "We are on your side!",

      messages: [
        "I don't tend to do what the courses suggest. \nHow to be?",
        "",
        "Agencies take the same approach for everyone,",
        "The courses on building a personal brand did not work,",
        "What content should I create?",
        "How to get started on a personal brand?",
      ],

      btn: "Find out the solution",
    },

    brand: {
      text: [
        "You can finally create your own brand without overstepping your physiological, psychological and social characteristics. ",
        "We have developed five models for building a personal brand. To define your model, we created a biopsychosocial approach to testing.",
        "The test will determine the characteristics of your nervous system. For this, it is important to know the indicators of Resilience - the body's ability to recover from stress, adapt to the changing demands of the external environment. As a result, build a powerful strategy for creating a personal brand that suits you.",
        "Everyone is different: someone is characterized by sociability and exchange of energy with society; someone appreciates calmness and draws inspiration, turning to their inner world. Do not break yourself to build a personal brand. You can maintain authenticity without sacrificing success, and to achieve over and above the result. ",
      ],
    },

    model: {
      title: "Personal Brand Models ",
      creator: {
        name: "Creator",
        description:
          "Content is king and Creators know how to tell a compelling story about events, personalities, hobbies and lifestyle. \n\nBlog, stream, YouTube channel, TikTok, or a group of friends - the audience is interested to follow every step.",
      },
      expert: {
        name: "Expert",
        description:
          "Knowledge and experience for Experts is more important than publicity, despite the fact that it is they who draw attention to their industry. \n\nDevelopment and the ability to share knowledge are the main priorities of Experts.",
      },
      insider: {
        name: "Insider",
        description:
          "An insider understands the importance of contacts. He knows that the main value of an idea, a company, a business is people. \n\n Personal recommendation is more important for them than being on social networks. Insiders are active and consistent, they easily find non-obvious solutions. ",
      },
      marketer: {
        name: "Marketer",
        description:
          "Marketers promote themselves through their businesses. They do not pay attention to the imposed norms. \n\nMarketers know how to interact with people and are ready to manifest themselves in different communication channels.  ",
      },
      lister: {
        name: "A-lister",
        description:
          "A-lister are recognized, whatever they do: a blog, a career in television or their own business. Their names become common nouns and do not need a description of their occupation. \n\nWhat is typical for A-Lists? Active interaction in society and a large number of social contacts. \n",
      },
      you: {
        name: "Who are you?",
        btn: "Find out the model",
      },
    },

    propose: {
      title: "Get to know your \npersonal brand model",
      description: "The test will take about 15 minutes",
      btn: "Start Test",
      free: "Free",
    },

    benefits: {
      title: "You will receive:",

      list: [
        "Description of your personal brand model. \nWe proceed from the characteristics of your nervous system \nand biopsychosocial qualities.",
        "A ready-made strategy for building a personal brand, including a content guide. You no longer have to pay money for courses and consultations, and most importantly - waste time.",
        "The answer is what model you are operating on and what needs to be changed and strengthened in order to achieve your goals as soon as possible.",
        "A structured, step-by-step plan that will eliminate the waste of unnecessary resources to achieve results. ",
        "A tool for composing a personal unique message with which you will enter the public space.  ",
      ],
    },

    faq: {
      btn: "Pass the test",

      questions: [
        {
          question: "Are you looking for a systematic scientific approach?",
          answer:
            "You are already working on a personal brand and you understand its value. But general information does not take into account the characteristics of your personality. You need flexible tools that suit your nervous system. You do not want to spend time and effort testing general hypotheses and recommendations, reviewing hours of videos. on YouTube. \n\nWe will give a deep understanding of your personality patterns and tell you how to develop what you like and suit you. Results and growth will not keep you waiting.",
        },
        {
          question: "We need to start. But where?",
          answer:
            "You understand that a personal brand is necessary for a career, expertise, reputation in the field and profit growth. But every time you feel confused:“ where to start? ”The number of decisions that need to be made frightens. There is no confidence in the result. This demotivates and postpones creation personal brand in your list of daily tasks. Afraid of failure, because you want to build a brand on the foundation of your own, and not someone else's. It is normal to study the success stories of others, but they cannot just be taken and copied. So it is with the model of a personal brand. The test for determining the model will give a clear and structured plan with which you will act confidently and at your own pace.",
        },
        {
          question: "Why do you need a personal brand?",
          answer:
            "You see people with a powerful personal brand and you think - where to start? What in your personality will be the starting point for starting a car from audience growth, new opportunities and the development of your business? Do you know enough about yourself to start spreading ideas that are meaningful to you? , share your achievements, inspire others and attract new opportunities? Working on yourself is the only profitable investment of resources. Define your personal brand model and learn a convenient way to interact with the audience and express yourself. ",
        },
      ],
    },
    footer: {
      policy: "Privacy Policy",
      help: "Contact us",
    },
  },

  pageError: {
    "404": "Page not found",
    "500": "An error has occurred. Please try again later",
    "isTechnical": `Sorry, technical work is in progress. We become better for you❤️️`,
    homeBtn: "Home",
  },

  pageBlogList: {
    title: "Our Blog",

    card: {
      read: "Read",
    },

    ads: {
      title:
        `You are a brand. 
Find out which methods of manifestation 
are right for you`,
      text: "Test to determine the model of a personal brand",
      btn: "Start Test",
    },
  },

  pageTest: {
    actions: {
      back: "Back",
      next: "Next",
      yes: "Yes",
      no: "No",
    },

    field: {
      placeholder: "Choose an option from the list",
    },

    intro: {
      title: "Test to determine the model \npersonal brand",
      text:
        "Questions about simple life situations await you, imagine yourself \n in each of them and give an answer. It will take no more than 15 minutes. \n\n" +
        "After passing the test, you will find out what model you live and act by now. The main value is the definition of your Personal Brand model, based on the unique qualities of your character, the peculiarities of the nervous system and the experience gained in society. \n\nAction!",
      btn: "Registration",
      ticker: "Take Action",
    },

    registration: {
      title_registration: "Let's get to know each other!",
      "title_final-registration": "Congratulations!",

      description_registration:
        "Fill in the registration fields \nand you will receive the result by mail",
      "description_final-registration":
        "The test passed. Now you recognize your personal brand model. \nWe will send you the result by Email. Check it out!",
      name: "Enter your name",
      email: "Enter your E-mail",
      lastName: "Please enter your last name",
      age: "Enter your age",
      onlyNumber: 'the field only accepts numbers ',
      fb: "Login with FB",
      google: "Login with Google",
      submit_registration: "Start Test",
      "submit_final-registration": "Submit",

      agreeTerms: "I agree to the processing of personal data ",
      agreeMailing:
        "I want to receive information for the development of my Personal Brand",
    },

    socialQuestion: {
      title: "What social networks can I find you on?",
      description:
        "We donate a monthly profile audit and it can be yours. \n" +
        "Fill in the social media fields. \n\n" +
        "There are a few steps left to take to your personal brand model!",
      submit: "Get the result",
      bound: "Connected",
    },

    result: {
      text: "Congratulations! \nThe result has been sent to your mail ",
      Failure: "Oh, something went wrong. \nCheck your payment details or contact the bank. \n ",
      btn: "Home",
    },
  },

  pageResult: {
    ticker: {
      intro: "You are {{ role }}!",
      intro2: "You are {{ role }}.",
      content: "YOUR CONTENT",
      plan: "Action plan",
      slogan: "True influence leads to action",
    },

    intro: {
      share: {
        title: "Tell your friends! ",
        btn: "Download video",
      },
    },
    properties: {
      title: "Understand yourself",
    },

    congratulation: {
      title: "Now You Know Your Personal Brand Model",

      description:
        "The biopsychosocial model is built on: the unique qualities of your character, the peculiarities of the nervous system and the experience gained in society. You have already laid down all the listed qualities, it remains to learn how to use them. \n\nYour personal brand model is the most suitable for your nervous system. Feel free to go to stress-free content recommendations. \n\nContent is the foundation of your brand strategy.",
    },

    model: {
      title: "You are currently acting as a {{ role }}",
    },

    plan: {
      description:
        "We have created a 5-step plan for you using the <0> Fly_Academy </0> methodology. You can achieve your goal and accelerate your journey towards building your personal brand. \n Now everything is in your hands:	",

      personal: {
        title: "Unique personal offer",
        description:
          "Answer the questions below honestly and form a unique personal proposal to move at your own pace. This is the foundation of your personal brand, how people will perceive you.",

        question: [
          "What do I like to do? ",
          "What do I do best? ",
          "What is my audience waiting for?",
        ],
      },
    },
    propose: {
      title: "Order a personal brand strategy and get:",
      titleNew: 'Personal brand model strategy',
      description: 'Learn how {{model}} can build an influential personal brand without stress',

      btn: "Pay",
      list: [
        "A ready-made strategy for building a personal brand, including a content guide. You no longer have to pay money for courses and consultations, and most importantly - waste time.",
        "The answer is what model you are operating on and what needs to be changed and strengthened in order to achieve your goals as soon as possible.",
        "A structured, step-by-step plan that will eliminate the waste of unnecessary resources to achieve results. ",
        "A tool for composing a personal unique message with which you will enter the public space.  ",
      ],
      listPhone: [
        "A ready-made strategy for building a personal brand, including a content guide. You no longer have to pay money for courses and consultations, and most importantly - waste time.",
        "The answer is what model you are operating on and what needs to be changed and strengthened in order to achieve your goals as soon as possible.",
        "A structured, step-by-step plan that will eliminate the waste of unnecessary resources to achieve results. ",
        "A tool for composing a personal unique message with which you will enter the public space.  ",
      ],
    },

    final: {
      title:
        "The first step has been taken. Now you will build a personal brand according to your own rules. The Personal Brand Book will help you with this - a method with which you can easily and independently create your brand.",
      description:
        "If you still have questions, please contact us",
      share: {
        hashtag: "#mypersonalbrandmodel",
      },
    },
  },
};
